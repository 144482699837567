:root {
  --toastify-icon-color-info: white;
  --toastify-icon-color-success:white;
  --toastify-icon-color-warning: white;
  --toastify-icon-color-error: white;
}

.Toastify__toast {
  display: flex;

  font-family: 'Roboto' !important;
  font-weight: bold;
  color: white;
}

.Toastify__progress-bar {
  visibility: hidden;
}

.Toastify__close-button {
  color: white;
  fill: white;
  opacity: 1;

  align-self: center;

  margin: 12px;
}

.Toastify__toast--info {
  background: rgb(51, 102, 255);
}
.Toastify__toast--success {
  background: #43a047;
}
.Toastify__toast--warning {
  background: #FFA000;
}
.Toastify__toast--error {
  background: #FF0000;
  width: 420px;
}
