.Grid24-root {
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
}

.Grid24-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.Grid24-item {
  margin: 0;
  box-sizing: border-box;
}

.Grid24-fullWidth {
  width: 100%;
}

.Grid24-spacing-1 {
  width: calc(100% + 8px);
  margin-left: -4px;
  margin-right: -4px;
}

.Grid24-spacing-1 > .Grid24-item {
  padding-left: 4px;
  padding-right: 4px;
}

.Grid24-spacing-2 {
  width: calc(100% + 16px);
  margin-left: -8px;
  margin-right: -8px;
}

.Grid24-spacing-2 > .Grid24-item {
  padding-left: 8px;
  padding-right: 8px;
}

.Grid24-spacing-3 {
  width: calc(100% + 24px);
  margin-left: -12px;
  margin-right: -12px;
}

.Grid24-spacing-3 > .Grid24-item {
  padding-left: 12px;
  padding-right: 12px;
}

.Grid24-spacing-4 {
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-right: -16px;
}

.Grid24-spacing-4 > .Grid24-item {
  padding-left: 16px;
  padding-right: 16px;
}

.Grid24-spacing-5 {
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: -20px;
}

.Grid24-spacing-5 > .Grid24-item {
  padding-left: 20px;
  padding-right: 20px;
}

.Grid24-spacing-6 {
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-right: -24px;
}

.Grid24-spacing-6 > .Grid24-item {
  padding-left: 24px;
  padding-right: 24px;
}

.Grid24-spacing-7 {
  width: calc(100% + 56px);
  margin-left: -28px;
  margin-right: -28px;
}

.Grid24-spacing-7 > .Grid24-item {
  padding-left: 28px;
  padding-right: 28px;
}

.Grid24-spacing-8 {
  width: calc(100% + 64px);
  margin-left: -32px;
  margin-right: -32px;
}

.Grid24-spacing-8 > .Grid24-item {
  padding-left: 32px;
  padding-right: 32px;
}

.Grid24-grid-1 {
  flex-grow: 0;
  max-width: 4.1666%;
  flex-basis: 4.1666%;
}

.Grid24-grid-2 {
  flex-grow: 0;
  max-width: 8.3333%;
  flex-basis: 8.3333%;
}

.Grid24-grid-3 {
  flex-grow: 0;
  max-width: 12.5%;
  flex-basis: 12.5%;
}

.Grid24-grid-4 {
  flex-grow: 0;
  max-width: 16.6666%;
  flex-basis: 16.6666%;
}

.Grid24-grid-5 {
  flex-grow: 0;
  max-width: 20.8333%;
  flex-basis: 20.8333%;
}

.Grid24-grid-6 {
  flex-grow: 0;
  max-width: 25%;
  flex-basis: 25%;
}

.Grid24-grid-7 {
  flex-grow: 0;
  max-width: 29.1666%;
  flex-basis: 29.1666%;
}

.Grid24-grid-8 {
  flex-grow: 0;
  max-width: 33.3333%;
  flex-basis: 33.3333%;
}

.Grid24-grid-9 {
  flex-grow: 0;
  max-width: 37.5%;
  flex-basis: 37.5%;
}

.Grid24-grid-10 {
  flex-grow: 0;
  max-width: 41.6666%;
  flex-basis: 41.6666%;
}

.Grid24-grid-11 {
  flex-grow: 0;
  max-width: 45.8333%;
  flex-basis: 45.8333%;
}

.Grid24-grid-12 {
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%;
}

.Grid24-grid-13 {
  flex-grow: 0;
  max-width: 54.1666%;
  flex-basis: 54.1666%;
}

.Grid24-grid-14 {
  flex-grow: 0;
  max-width: 58.3333%;
  flex-basis: 58.3333%;
}

.Grid24-grid-15 {
  flex-grow: 0;
  max-width: 62.5%;
  flex-basis: 62.5%;
}

.Grid24-grid-16 {
  flex-grow: 0;
  max-width: 66.6666%;
  flex-basis: 66.6666%;
}

.Grid24-grid-17 {
  flex-grow: 0;
  max-width: 70.8333%;
  flex-basis: 70.8333%;
}

.Grid24-grid-18 {
  flex-grow: 0;
  max-width: 75%;
  flex-basis: 75%;
}

.Grid24-grid-19 {
  flex-grow: 0;
  max-width: 79.1666%;
  flex-basis: 79.1666%;
}

.Grid24-grid-20 {
  flex-grow: 0;
  max-width: 83.3333%;
  flex-basis: 83.3333%;
}

.Grid24-grid-21 {
  flex-grow: 0;
  max-width: 87.5%;
  flex-basis: 87.5%;
}

.Grid24-grid-22 {
  flex-grow: 0;
  max-width: 91.6666%;
  flex-basis: 91.6666%;
}
.Grid24-grid-23 {
  flex-grow: 0;
  max-width: 95.8333%;
  flex-basis: 95.8333%;
}
.Grid24-grid-24 {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
}

.Form-container-margin {
  margin-top: 7px;
}
